<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row class="pt-3">
                <v-flex xs6 lg3 md4 pl-5 py-1 class="align-center d-flex flex-row">
                    {{ $t('message.status') + ":" }}
                    <v-chip color="success ml-5" v-if="serverOperational">{{ $t('message.operational') }}</v-chip>
                    <v-chip color="warning ml-5" v-else>{{ $t('message.down') }}</v-chip>
                </v-flex>
            </v-layout>
            <v-layout row class="pt-2">
                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                    <v-btn :loading="loading.rebootServer" dark @click="rebootServer()"><v-icon class="mr-3">mdi-power-cycle</v-icon>{{ $t('message.rebootServer') }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>

import {api} from "Api";

export default {
    name: "PdfServer",
    data() {
        return {
            loader: false,
            loading: {
                rebootServer: false
            },
            serverOperational: false
        }
    },
    methods: {
        checkServerStatus() {
            api
                .get(process.env.VUE_APP_PDF_SERVER)
                .then(() => {
                    this.serverOperational = false
                })
                .catch((error) => {
                    if(error.response.status == 404){
                        this.serverOperational = true
                    } else {
                        this.serverOperational = false
                    }
                })
        },
        async rebootServer(){
            if (await this.$root.$confirm(this.$t('message.rebootPdfServer'), this.$t('message.confirmations.continuePdfServerReboot'), {color: 'success'})) {
                this.loading.rebootServer = true
                api
                    .post('/admin/pdf-server/reboot')
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.pdfServerRebooted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.rebootServer = false
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfServerNotRebooted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfServerNotRebooted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.rebootServer = false
                    })
            }
        }
    },
    created() {
        this.checkServerStatus()
    },
    mounted () {
        this.$title =  this.$t('message.titles.pdfServer')
    }
}
</script>

<style scoped>

</style>